import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 3264.000000 1088.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1088.000000) scale(0.100000,-0.100000)">



<path d="M10720 5410 l0 -1240 810 0 c796 0 810 0 865 21 107 40 185 122 222
232 15 46 18 118 21 654 2 361 -1 631 -7 673 -21 157 -134 286 -283 326 -43
11 -170 14 -650 14 l-598 0 0 280 0 280 -190 0 -190 0 0 -1240z m1540 -285 l0
-575 -580 0 -580 0 0 575 0 575 580 0 580 0 0 -575z"/>
<path d="M12947 6644 c-4 -4 -7 -562 -7 -1241 l0 -1233 195 0 195 0 -2 1238
-3 1237 -186 3 c-102 1 -188 -1 -192 -4z"/>
<path d="M19357 6644 c-4 -4 -7 -130 -7 -280 l0 -273 -602 -3 -603 -3 -66 -31
c-117 -56 -184 -134 -214 -250 -13 -49 -15 -156 -15 -669 0 -398 4 -632 11
-670 21 -115 89 -205 199 -261 l55 -29 808 -3 807 -2 -2 1237 -3 1238 -181 3
c-99 1 -183 -1 -187 -4z m-7 -1519 l0 -575 -560 0 -560 0 0 575 0 575 560 0
560 0 0 -575z"/>
<path d="M22252 5543 l3 -1098 28 -60 c36 -78 104 -146 181 -182 61 -28 61
-28 304 -31 l242 -3 0 191 0 190 -190 0 -190 0 0 590 0 590 188 2 187 3 3 178
2 177 -190 0 -190 0 0 275 0 275 -190 0 -190 0 2 -1097z"/>
<path d="M6400 5130 l0 -960 190 0 190 0 0 765 0 765 525 0 525 0 0 -765 0
-765 195 0 195 0 0 769 c0 496 -4 797 -11 847 -20 142 -82 229 -196 275 l-58
24 -777 3 -778 2 0 -960z"/>
<path d="M8785 6076 c-115 -37 -198 -109 -248 -214 l-32 -67 -3 -635 c-2 -413
1 -658 8 -703 10 -63 41 -139 72 -177 28 -34 86 -69 146 -89 62 -21 73 -22
750 -19 l687 3 67 33 c75 37 110 72 147 152 l26 55 0 695 0 695 -27 57 c-37
80 -107 152 -187 191 l-66 32 -650 2 c-507 2 -659 -1 -690 -11z m1245 -951 l0
-575 -575 0 -575 0 0 575 0 575 575 0 575 0 0 -575z"/>
<path d="M13885 6076 c-139 -34 -228 -138 -255 -296 -8 -44 -10 -263 -8 -690
l3 -625 23 -58 c31 -76 77 -135 136 -174 95 -64 74 -63 892 -63 l743 0 3 28
c2 15 2 100 0 190 l-3 162 -709 0 -710 0 0 195 0 194 628 3 627 3 56 26 c104
49 175 143 199 264 8 40 10 146 8 315 -3 235 -5 259 -26 311 -42 104 -135 184
-250 214 -76 20 -1277 21 -1357 1z m1255 -561 l0 -195 -570 0 -570 0 0 188 c0
104 3 192 7 195 3 4 260 7 570 7 l563 0 0 -195z"/>
<path d="M16045 6076 c-122 -30 -211 -112 -246 -226 -19 -62 -20 -88 -17 -361
3 -293 3 -294 29 -349 37 -80 81 -125 157 -162 l67 -33 570 -5 570 -5 3 -192
2 -193 -690 0 -690 0 0 -190 0 -190 753 2 752 3 56 26 c79 37 131 88 165 161
l29 63 0 315 0 315 -26 55 c-50 106 -150 180 -270 200 -38 6 -274 10 -581 10
l-518 0 0 190 0 190 653 2 652 3 3 193 2 192 -687 -1 c-511 0 -701 -4 -738
-13z"/>
<path d="M20032 5898 l3 -193 763 -3 762 -2 0 -190 0 -190 -583 0 c-378 0
-604 -4 -642 -11 -79 -14 -148 -51 -202 -106 -55 -57 -71 -87 -89 -171 -19
-93 -19 -482 1 -574 24 -117 99 -209 212 -261 l48 -22 823 -3 822 -2 0 767 c0
486 -4 792 -11 833 -25 159 -120 265 -274 306 -40 11 -213 14 -843 14 l-792 0
2 -192z m1528 -1153 l0 -195 -575 0 -575 0 0 188 c0 104 3 192 7 195 3 4 262
7 575 7 l568 0 0 -195z"/>
<path d="M23212 5898 l3 -193 763 -3 762 -2 0 -190 0 -190 -587 0 c-471 0
-601 -3 -656 -15 -121 -25 -224 -115 -264 -228 -16 -45 -18 -88 -18 -332 0
-210 3 -290 14 -320 36 -105 123 -196 226 -234 55 -21 71 -21 860 -21 l805 0
0 804 c0 763 -1 807 -19 865 -23 74 -70 138 -132 181 -101 68 -48 65 -947 68
l-812 3 2 -193z m1526 -1155 l2 -193 -575 0 -575 0 0 188 c0 104 3 192 7 195
3 4 261 6 572 5 l566 -3 3 -192z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
